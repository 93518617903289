
















































































































































































































































































































































































































.listen_content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  .content {
    flex: 2;
    box-sizing: border-box;
    background: white;
    overflow-y: scroll;
    border-radius: 8px;
    .title {
      background: #e9e9e9;
      border-radius: 30px;
      padding: 0 30px;
      color: gray;
      display: inline-block;
      margin-bottom: 20px;
    }
    .text_content {
      text-align: left;
      text-indent: 2em;
      color: #666;
    }
    .question_outer {
      text-align: left;
      margin-top: 20px;
      .question_item {
        text-indent: 2em;
      }
    }
  }
}
