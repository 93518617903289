





















































































































































































































































































































.listen_content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  .content {
    flex: 2;
    padding: 10px;
    box-sizing: border-box;
    background: white;
    overflow-y: scroll;
    border-radius: 8px;
    .title {
      font-size: 40rpx;
      background: #e9e9e9;
      border-radius: 15px;
      padding: 0 15px;
      color: gray;
      display: inline-block;
      margin-bottom: 20px;
    }
    .text_content {
      text-align: left;
      text-indent: 2em;
      color: #666;
    }
    .outer_question {
      text-align: left;
    }
  }
}
