









































































































































































































































































































































.listen_content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  .content {
    flex: 2;
    padding: 30px;
    box-sizing: border-box;
    background: white;
    overflow-y: scroll;
    border-radius: 8px;
    .title {
      font-size: 40rpx;
      background: #e9e9e9;
      border-radius: 30rpx;
      padding: 0 30px;
      color: gray;
      display: inline-block;
      margin-bottom: 20px;
    }
    .text_content {
      text-align: left;
      text-indent: 2em;
      color: #666;
    }
    .outer_question {
      text-align: left;
      box-sizing: border-box;
      .question_content {
        text-indent: 2em;
        color: #666;
      }
      .question_outer {
        padding: 20rpx 0;
        box-sizing: border-box;
        .radio_group {
          margin-top: 20px;
          width: 100%;
          font-size: 1rem;
          .label_item {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            .lable_radio {
              margin-right: 10px;
              position: relative;
            }
            .option_text {
              margin-left: 10px;
            }
          }
        }
      }
    }
  }
}
