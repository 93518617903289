



























































































































































































































































































.inner_outer_page {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: row;
  .title_static {
    background: white;
    .title_box {
      height: 70px;
      line-height: 70px;
      text-align: center;
    }
    #main {
      margin: 0 auto;
    }
  }
  .right_content {
    flex: 2;
    display: flex;
    flex-direction: column;
    height: 100%;
    border-left: solid 1px #f5f5f5;
    background: white;
    overflow-x: auto;
    .nav_scroll {
      top: 0;
      z-index: 100;
      white-space: nowrap;
      box-sizing: border-box;
      height: 70px;
      line-height: 70px;
      overflow-x: scroll;
      overflow-y: hidden;
      &.fixed_title {
        position: fixed;
        top: 136px;
        left: 5%;
        width: 90%;
        border-bottom: solid 1px #f5f5f5;
        background: white;
      }
      .nav_title {
        display: inline-block;
        text-align: center;
        padding: 0 20px;
      }
    }
    .empty_nav {
      height: 70px;
    }
    .inner_content {
      height: 100%;
      overflow-y: scroll;
    }
    .empty_line {
      height: 20px;
      width: 100%;
    }
  }
}
@media only screen and (max-width: 1024px) {
  .inner_outer_page {
    flex-direction: column;
    overflow-y: scroll;
  }
  .title_static {
    width: 100%;
    #main {
      margin: 0 auto;
    }
  }
  .right_content {
    overflow: unset !important;
    border-bottom: solid 1px #f5f5f5;
    box-sizing: border-box;
    background-color: white;
  }
  .right_content {
    flex: none !important;
    height: auto !important;
  }
}
