.footer {
  display: flex;
  align-items: center;
  height: 100px;
}
.footer .play_icon {
  font-size: 60px;
}
.footer .outer_container {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.footer .outer_container .outer_submit {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
.footer .outer_container .outer_submit .outer_box {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.footer .outer_container .outer_submit .outer_box .box {
  /* 最外层的盒子 */
  width: 58px;
  height: 58px;
  margin: 0 auto;
  position: relative;
  border-radius: 50%;
  float: left;
  /* 
       这是需要旋转的div(没有被mask遮盖展示出来的部分作为倒计时的线条)
       为了方便理解，下面用deeppink和cyan分别设置了左右两边div的颜色
      */
  /*
        1.设置圆角，圆角大小为高度的一半
        2.这只旋转的中心店，这是左边圆，中心点设置到右边中心点，右边圆则设置到左边中心点
        */
}
.footer .outer_container .outer_submit .outer_box .box .left_box,
.footer .outer_container .outer_submit .outer_box .box .right_box {
  /* 
        左右两边用于 隐藏 旋转的div的盒子 
        通过overflow来隐藏内部div旋转出去的部分
      */
  position: absolute;
  top: -1px;
  width: 30px;
  height: 60px;
  overflow: hidden;
  z-index: 1;
  font-size: 0;
}
.footer .outer_container .outer_submit .outer_box .box .left_box {
  left: 0px;
}
.footer .outer_container .outer_submit .outer_box .box .right_box {
  right: 0px;
}
.footer .outer_container .outer_submit .outer_box .box .left_item,
.footer .outer_container .outer_submit .outer_box .box .right_item {
  width: 30px;
  height: 60px;
}
.footer .outer_container .outer_submit .outer_box .box .left_item {
  border-top-left-radius: 60px;
  border-bottom-left-radius: 60px;
  -webkit-transform-origin: right center;
  transform-origin: right center;
  background-color: #fff;
}
.footer .outer_container .outer_submit .outer_box .box .right_item {
  border-top-right-radius: 60px;
  border-bottom-right-radius: 60px;
  -webkit-transform-origin: left center;
  transform-origin: left center;
  background-color: #fff;
}
.footer .outer_container .outer_submit .outer_box .box .mask {
  /* 遮住div多余的部分，呈现出线条的效果 */
  position: absolute;
  top: 2px;
  left: 2px;
  right: 2px;
  bottom: 2px;
  z-index: 2;
  border-radius: 50%;
  background-color: #f5f5f5;
}
.footer .outer_container .outer_submit .outer_box .box .mask .anticon-yyashow {
  font-size: 54px;
  position: absolute;
  top: -1px;
  bottom: 0;
  right: 0;
  margin: 0 auto;
  line-height: 105%;
  left: 0px;
}
.footer .outer_container .outer_submit .outer_box .box .mask .anticon-yyashow.anticon-luyin2 {
  top: -1px;
}
.footer .outer_container .outer_submit .outer_box .right_text {
  font-size: 1rem;
  margin-left: 10px;
}
.footer .outer_container .outer_submit .submit_btn {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  line-height: 40px;
  text-align: center;
  border: solid 1px white;
  font-size: 1rem;
}
.footer .outer_container .record_text {
  font-size: 0.8rem;
  color: #999;
  text-align: center;
  width: 58px;
}
