.listen_content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
  .inner_content {
    flex: 2;
    box-sizing: border-box;
    background: white;
    overflow-y: scroll;
    border-radius: 8px;
    position: relative;
    .game_number {
      border-radius: 30px;
      padding: 0 30px;
      display: inline-block;
      margin-bottom: 20px;
      font-size: 1rem;
      color: #1890ff;
      background: #e6f7ff;
    }
    .text_content {
      text-align: left;
      color: #666;
    }
    // 选择题容器
    .choose_question_box {
      .choose_question_item {
        .single_question {
          margin-bottom: 20px;
        }
        .question_title {
          margin-top: 10px;
          font-size: 1.1rem;
        }
        .radio_group {
          display: flex;
          text-align: left;
          font-size: 1.1rem;
          .label_item {
            margin-top: 20px;
            display: flex;
            align-items: center;
            line-height: 1;
            .ant-radio-wrapper {
              line-height: 1;
            }
            .label_radio {
              position: relative;
              margin-right: 5px;
            }
            .radio_text {
              margin-left: 10px;
              vertical-align: middle;
            }
          }
          .label_item:first-child {
            margin-top: 15px;
          }
        }
      }
    }
    // 填空题容器
    .blank_content_box {
      .blank_content_item {
        .img_box {
          text-align: center;
          position: relative;
          .file_url {
            width: 400px;
          }
          .right_btn {
            position: absolute;
            bottom: 0;
            right: 0;
            background: rgba(0, 0, 0, 0.5);
            color: white;
            padding: 0rpx 10px;
          }
        }
        .tip_title {
          margin-top: 10px;
        }
        .blank_input_box {
          text-align: left;
          margin-top: 10px;
          display: flex;
          align-items: baseline;
          .blank_item_left {
            margin-right: 10px;
          }
          .blank_item_input {
            width: 90%;
            display: inline-block;
            border: solid 1px #999;
            padding-left: 10px;
          }
        }
      }
    }
    // 情景题容器
    .scene_question_outer {
      .scene_question_tips {
        text-indent: 2em;
      }
      .scene_question_item {
        margin-top: 20px;
        .scene_question_title {
          text-indent: 2em;
        }
      }
    }
  }
}
