
















































































































































































































































































































































































































































































.outer_page {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  .inner_content {
    flex: 2;
    position: relative;
  }
}
.exam_content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  .exam_left {
    width: 30%;
    height: 100%;
    overflow-y: scroll;
    background: white;
    border-right: solid 1px rgb(232, 232, 232);
    .ant-menu {
      border: none;
    }
  }
  .exam_right {
    flex: 2;
    overflow-x: hidden;
    overflow-y: scroll;
    position: relative;
    display: flex;
    flex-direction: column;
    background: white;
    padding-top: 20px;
    .exam_game_title {
      text-align: center;
      font-size: 1.25rem;
      font-weight: bold;
      .right_toggle {
        position: absolute;
        right: 20px;
        font-weight: lighter;
        font-size: 0.9rem;
        .anticon-yyashow {
          font-size: 14px;
        }
      }
    }
    .content_container {
      flex: 2;
      position: relative;
    }
  }
}
