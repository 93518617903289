

















































































































































































































































































@import '../../exam.less';
.label_item {
  margin-bottom: 10px;
  width: 50%;
  .radio_img {
    width: 200px;
    margin-left: 10px;
  }
}
