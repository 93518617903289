.listen_content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px 50px;
}
.listen_content .inner_content {
  flex: 2;
  box-sizing: border-box;
  background: white;
  overflow-y: scroll;
  border-radius: 8px;
  position: relative;
}
.listen_content .inner_content .game_number {
  border-radius: 30px;
  padding: 0 30px;
  display: inline-block;
  margin-bottom: 20px;
  font-size: 1rem;
  color: #1890ff;
  background: #e6f7ff;
}
.listen_content .inner_content .text_content {
  text-align: left;
  color: #666;
}
.listen_content .inner_content .choose_question_box .choose_question_item .single_question {
  margin-bottom: 20px;
}
.listen_content .inner_content .choose_question_box .choose_question_item .question_title {
  margin-top: 10px;
  font-size: 1.1rem;
}
.listen_content .inner_content .choose_question_box .choose_question_item .radio_group {
  display: flex;
  text-align: left;
  font-size: 1.1rem;
}
.listen_content .inner_content .choose_question_box .choose_question_item .radio_group .label_item {
  margin-top: 20px;
  display: flex;
  align-items: center;
  line-height: 1;
}
.listen_content .inner_content .choose_question_box .choose_question_item .radio_group .label_item .ant-radio-wrapper {
  line-height: 1;
}
.listen_content .inner_content .choose_question_box .choose_question_item .radio_group .label_item .label_radio {
  position: relative;
  margin-right: 5px;
}
.listen_content .inner_content .choose_question_box .choose_question_item .radio_group .label_item .radio_text {
  margin-left: 10px;
  vertical-align: middle;
}
.listen_content .inner_content .choose_question_box .choose_question_item .radio_group .label_item:first-child {
  margin-top: 15px;
}
.listen_content .inner_content .blank_content_box .blank_content_item .img_box {
  text-align: center;
  position: relative;
}
.listen_content .inner_content .blank_content_box .blank_content_item .img_box .file_url {
  width: 400px;
}
.listen_content .inner_content .blank_content_box .blank_content_item .img_box .right_btn {
  position: absolute;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.5);
  color: white;
  padding: 0rpx 10px;
}
.listen_content .inner_content .blank_content_box .blank_content_item .tip_title {
  margin-top: 10px;
}
.listen_content .inner_content .blank_content_box .blank_content_item .blank_input_box {
  text-align: left;
  margin-top: 10px;
  display: flex;
  align-items: baseline;
}
.listen_content .inner_content .blank_content_box .blank_content_item .blank_input_box .blank_item_left {
  margin-right: 10px;
}
.listen_content .inner_content .blank_content_box .blank_content_item .blank_input_box .blank_item_input {
  width: 90%;
  display: inline-block;
  border: solid 1px #999;
  padding-left: 10px;
}
.listen_content .inner_content .scene_question_outer .scene_question_tips {
  text-indent: 2em;
}
.listen_content .inner_content .scene_question_outer .scene_question_item {
  margin-top: 20px;
}
.listen_content .inner_content .scene_question_outer .scene_question_item .scene_question_title {
  text-indent: 2em;
}
.listen_content1 {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.listen_content1 .content {
  flex: 2;
  padding: 30rpx;
  box-sizing: border-box;
  background: white;
  overflow-y: scroll;
  border-radius: 8px;
}
.listen_content1 .content .title {
  font-size: 40rpx;
  background: #e9e9e9;
  border-radius: 30rpx;
  padding: 0 30rpx;
  color: gray;
  display: inline-block;
  margin-bottom: 20rpx;
}
.listen_content1 .content .text_content {
  text-align: left;
  text-indent: 2em;
  font-size: 32rpx;
  color: #666;
}
.listen_content1 .content .outer_question {
  text-align: left;
  box-sizing: border-box;
}
.listen_content1 .content .outer_question .question_content {
  font-size: 32rpx;
  text-indent: 2em;
  color: #666;
}
.listen_content1 .content .outer_question .question_outer {
  padding: 20rpx 0;
  box-sizing: border-box;
}
.listen_content1 .content .outer_question .question_outer .radio_group {
  margin-top: 20rpx;
}
.listen_content1 .content .outer_question .question_outer .radio_group .label_item {
  margin-bottom: 20rpx;
  display: flex;
}
.listen_content1 .content .outer_question .question_outer .radio_group .label_item .lable_radio {
  margin-right: 10rpx;
  position: relative;
  top: -5rpx;
}
.listen_content1 .content .outer_question .question_outer .radio_group .label_item .option_text {
  margin-left: 10rpx;
}
