

























































































































































































































































































































@import './unti.less';
.content {
  display: flex;
  flex-direction: column;
  .inner_question_container {
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    flex: 2;
  }
}
