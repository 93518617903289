






















































































































































































































































































































































































































.listen_content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  overflow: hidden;
  .content {
    flex: 2;
    box-sizing: border-box;
    background: white;
    overflow-y: scroll;
    border-radius: 8px;
    padding-bottom: 20px;
    .title {
      background: #e9e9e9;
      padding: 0 30px;
      color: gray;
      display: inline-block;
      margin-bottom: 20px;
    }
    .text_content {
      text-align: left;
      color: #666;
    }
    .img_box {
      text-align: center;
      position: relative;
      min-height: 150px;
      .file_url {
        width: 100%;
        max-width: 500px;
      }
      .right_btn {
        position: absolute;
        bottom: 0;
        right: 0;
        background: rgba(0, 0, 0, 0.5);
        color: white;
        padding: 0px 20px;
      }
    }
    .inner_word {
      color: #666;
      text-align: left;
      .word_item {
        height: 40px;
        overflow: hidden;
        display: flex;
        align-items: flex-end;
        .inner_left {
          margin-right: 10px;
        }
        .inner_right {
          padding-left: 10px;
          flex: 2;
          display: inline-block;
          text-align: end;
          text-align: left;
          border-bottom: solid 1px #999;
        }
      }
    }
    .outer_question {
      margin-top: 20px;
      text-align: left;
    }
  }
  .mask_outer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    z-index: 10000;
    .img_url {
      width: 100%;
      margin-top: 100px;
    }
  }
}
