.content_container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
  .content {
    flex: 2;
    padding: 30px;
    box-sizing: border-box;
    background: white;
    overflow-y: scroll;
    border-radius: 8px;
    // 题型标题
    .question_num_title {
      background: #e9e9e9;
      border-radius: 30px;
      padding: 0 30px;
      color: gray;
      display: inline-block;
      margin-bottom: 20px;
    }
    // 题目要求文本
    .question_claim_content {
      text-align: left;
      text-indent: 2em;
      color: #666;
    }
    // 内部问题容器
    .inner_question_container {
      text-align: center;
      .question_item_img {
        width: 300px;
      }
    }
  }
}
