





























































































































































































































































































































@import '../../exam.less';
.listen_content1 {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  .content {
    flex: 2;
    padding: 30rpx;
    box-sizing: border-box;
    background: white;
    overflow-y: scroll;
    border-radius: 8px;
    .title {
      font-size: 40rpx;
      background: #e9e9e9;
      border-radius: 30rpx;
      padding: 0 30rpx;
      color: gray;
      display: inline-block;
      margin-bottom: 20rpx;
    }
    .text_content {
      text-align: left;
      text-indent: 2em;
      font-size: 32rpx;
      color: #666;
    }
    .outer_question {
      text-align: left;
      text-indent: 2em;
      font-size: 34rpx;
    }
    .question_content {
      text-align: left;
      font-size: 32rpx;
      color: #666;
    }
  }
}
