.listen_content {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
}
.listen_content .content {
  flex: 2;
  box-sizing: border-box;
  background: white;
  overflow-y: scroll;
  border-radius: 8px;
}
.listen_content .content .title {
  background: #e9e9e9;
  border-radius: 30px;
  padding: 0 30px;
  color: gray;
  display: inline-block;
  margin-bottom: 20px;
}
.listen_content .content .text_content {
  text-align: left;
  text-indent: 2em;
  color: #666;
}
.listen_content .content .question_outer {
  text-align: left;
  margin-top: 20px;
}
.listen_content .content .question_outer .question_item {
  text-indent: 2em;
}
