.content_container {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 30px;
}
.content_container .content {
  flex: 2;
  padding: 30px;
  box-sizing: border-box;
  background: white;
  overflow-y: scroll;
  border-radius: 8px;
}
.content_container .content .question_num_title {
  background: #e9e9e9;
  border-radius: 30px;
  padding: 0 30px;
  color: gray;
  display: inline-block;
  margin-bottom: 20px;
}
.content_container .content .question_claim_content {
  text-align: left;
  text-indent: 2em;
  color: #666;
}
.content_container .content .inner_question_container {
  text-align: center;
}
.content_container .content .inner_question_container .question_item_img {
  width: 300px;
}
.content {
  display: flex;
  flex-direction: column;
}
.content .inner_question_container {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  flex: 2;
}
