.outer_page {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
}
.outer_page .inner_content {
  flex: 2;
  position: relative;
}
.exam_content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
}
.exam_content .exam_left {
  width: 30%;
  height: 100%;
  overflow-y: scroll;
  background: white;
  border-right: solid 1px #e8e8e8;
}
.exam_content .exam_left .ant-menu {
  border: none;
}
.exam_content .exam_right {
  flex: 2;
  overflow-x: hidden;
  overflow-y: scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  background: white;
  padding-top: 20px;
}
.exam_content .exam_right .exam_game_title {
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
}
.exam_content .exam_right .exam_game_title .right_toggle {
  position: absolute;
  right: 20px;
  font-weight: lighter;
  font-size: 0.9rem;
}
.exam_content .exam_right .exam_game_title .right_toggle .anticon-yyashow {
  font-size: 14px;
}
.exam_content .exam_right .content_container {
  flex: 2;
  position: relative;
}
